@import 'colors.scss';
@import 'variables.scss';
@import 'functions.scss';
@import 'dialog-custom.scss';
@import 'toasts.scss';
@import 'dialog.scss';
// import cropper css
@import 'cropperjs/dist/cropper.min';
// import simplebar css
@import 'simplebar/src/simplebar';
// fonts
@import 'fonts.scss';
@import 'tooltip-custom.scss';
@import 'dropDown.scss';

:root {
  // circle progressIndicator
  --circle-indicator-color: #{$mainColor1};
  --bar-indicator-color: #{$mainColor1};
}

// bootstrap like styles
html {
  min-height: 100%;
  height: 100%;
  font-size: 10px; // base font size used by rem units
}

body {
  min-height: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  font-family: $font-family-sans-serif;
  line-height: 1.42857;
  font-size: 1.5rem;
  margin: 0;
}

input, textarea, select, button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.body {
  padding-top: $topBarHeight;
}

.hide {
  display: none;
}

.hidden {
  display: none !important;
}

.text-center {
  text-align: center;
}

button {
  outline: none !important;

  background: $mainColor1;
  border-radius: 8px;
  padding: 0 15px;
  border: none;
  color: $mainColor0;
  font-size: 12px;
  line-height: 2.5;

  position: relative;
  z-index: 1;
  cursor: pointer;

  &:not(.noDefStyles) {
    &:focus {
      &:before {
        content: ' ';
        position: absolute;
        z-index: -1;
        top: -3px;
        left: -3px;
        right: -3px;
        bottom: -3px;
        border: 2px solid $mainColor11;
        border-radius: 10px;
      }
    }

    &.focus--mouse {
      &:before {
        display: none !important;
      }
    }
  }

  span {
    vertical-align: middle;
  }

  &.btnIcon {
    background: none;
    border-radius: 0;
    text-decoration: underline;
    line-height: 1.5;
    color: $mainColor9;
    padding: 0;

    &:hover {
      background: transparent;
      color: $mainColor1;
    }

    &:disabled {
      color: $mainColor5;
      background: transparent;
    }

    &:focus:before {
      border-radius: 0;
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;
      border: 1px dashed #5b6b6e;
    }

    .icon {
      margin-right: 0;
    }
  }

  &.btnPlain {
    background: none;
    border-radius: 0;
    padding: inherit;
    color: $mainColor9;
    text-decoration: underline;
    line-height: 1.5;

    &:hover {
      background: transparent;
      color: $mainColor1;
    }

    &:disabled {
      background: transparent;
      color: $mainColor5;
    }

    &:focus:before {
      border-radius: 0;
      top: 0;
      left: -10px;
      right: -10px;
      bottom: 0;
      border: 1px dashed #5b6b6e;
    }

  }

  .icon {
    text-decoration: none;
    margin-right: 8px;
    vertical-align: middle;
    position: static;
  }

  &:hover {
    background: $mainColor4;
  }

  &:disabled {
    background: $mainColor1;
    opacity: 0.4;
    cursor: default;
  }

}

a {
  text-decoration: none;
  cursor: pointer;
  color: $mainColor1;

  &:focus {
    outline: 1px dashed $mainColor11;
    outline-offset: 3px;
  }

  &.focus--mouse {
    outline: none !important;
  }

}

* {
  box-sizing: border-box;
}

*:before, *:after {
  box-sizing: border-box;
}


.radio, .checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.noPadding {
  padding: 0 !important;
}

.noMargin {
  margin: 0 !important;
}

.clearfix {
  @include clearFix();
}

.floatRight {
  float: right;
}

.star {
  flex: 1;
}

.viewContentRoot {
  background: $viewBGColor;
  min-height: 100%;
  position: relative;
  height: 100%;

  & > .loadingModule {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    font-weight: $lightFont;
    color: $mainColor22;
  }

  @include sm {
    overflow-y: auto;
    // enable momentum scrolling
    &.ios {
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
  }
}

.viewContent {
  border-radius: 0;
  box-sizing: border-box;
  padding: 0;
  min-height: initial;
  height: 100%;
  &.loading {
    opacity: 0;
  }
  &.fullScreen {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.contentWithSidebar {
  flex: 1;
  min-height: 0;
  overflow-y: auto;
  position: relative;
  z-index: 1000;
  // enable momentum scrolling
  &.ios {
    overflow-y: visible;
  }

  @include sm {
    overflow-y: visible;
    display: flex;
    flex-direction: row;
    &.ios {
      overflow-y: visible;
    }
  }
}

.dynWidthContent {
  transition: width 0.5s $defaultAnimationEasing;
  flex: 1;
  &.fullScreen {
    display: flex;
    flex-direction: column;
  }
}


// custom toast module styles
.toastModule.iziToast.toastMessage > .iziToast-body > p {
  font-size: 1.4rem;
}

// croppingService styles
.croppedImageContainer {
  position: absolute;
  z-index: 90; /* lower than 100: It's mce editor toolbars/menus */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  &:before {
    content: '';
    position: fixed;
    z-index: 80; /* lower than 100: It's mce editor toolbars/menus */
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
  }


  .croppedImageContainerInner {
    position: absolute;
    z-index: 90; /* lower than 100: It's mce editor toolbars/menus */
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      left: -1px;
      top: -1px;
      right: -1px;
      bottom: -1px;
      border: 1px dotted $mainColor1;
    }

    .cropper-container {
      z-index: 10;
    }

    $cropperButtonsSize: 32px;

    .cropperButtons {
      position: absolute;
      z-index: 1;
      left: 50%;
      white-space: nowrap;
      transform: translate(-50%, 0);
      bottom: -$cropperButtonsSize;

      button {
        border-radius: 0;
        width: $cropperButtonsSize;
        height: $cropperButtonsSize;
        padding: 2px;

        .icon {
          display: inline-block;
          margin: 0;
          line-height: 1;
          font-size: 1.8rem;

          svg {
            width: 1em;
            height: 1em;
          }
        }

        &:focus {
          outline-offset: -5px;
          outline: 1px dashed $mainColor0 !important;

          &:before {
            display: none;
          }
        }

        &.focus--mouse {
          outline: none !important;
        }
      }

      .delim {
        width: 5px;
        display: inline-block;
      }
    }
  }
}


.subPageTitleBlock {
  padding: 1px 0;
  margin-bottom: 5px;
  position: relative;
}

.subPageTitle {
  font-family: $portal-font-family-narrow;
  font-size: 2.4rem;
  color: $mainColor9;
  font-weight: $narrowLightFont;
  margin: 10px 20px 0 20px;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @include sm {
    font-size: 3.6rem;
    margin: 20px 20px 0 20px;
  }
}

.subPageDescription {
  font-weight: $lightFont;
  margin: 0 20px;
  padding: 10px 0;
  border-top: 1px solid $mainColor2;
  color: $mainColor6;
}


input[type=text], input[type=password] {
  color: #fff;
  background: transparent;
  border: 2px solid transparent;
  outline: none;
  border-bottom: 1px solid $mainColor7;
  border-radius: 0;
}

.textFieldGroup {
  position: relative;
  margin-bottom: 20px;

  &.withLabel {
    margin-bottom: $inputLabelHeight;
  }

  &.init {
    label {
      transition: 0.2s ease all;
    }
  }

  input {
    position: relative;
    z-index: 3;
    padding: $defaultVertPadding 10px;
    width: 100%;
    border: 1px solid transparent;
    border-bottom: 1px solid $mainColor10;
    background: $inputFieldBG;

    &:disabled {
      color: $mainColor5;
    }

    &.password.hasValue {
      border-right: $passwordButtonWidth solid transparent;
    }
  }

  label {
    color: $mainColor22;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: $normalFont;
    position: absolute;
    z-index: 4;
    pointer-events: none;
    left: 0;
    top: 50%;
    margin-bottom: 0;
    transform: translate(0, -50%);
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    padding: 1px 7px 1px 10px;
    width: 100%;
    height: $inputLabelHeight;
    line-height: $inputLabelHeight;
  }

  .bar {
    position: absolute;
    bottom: -($inputLabelHeight);
    width: 100%;
    z-index: 1;
    height: $inputLabelHeight;
    background: $mainColor10;
    transform: scaleY(0);
    transform-origin: 0 0;
    transition: 0.2s ease transform;
  }

  .btn-reveal {
    padding: 5px 15px 5px 15px;
    color: #fff;
    background: transparent;
    outline: none;
    border-bottom: 1px solid $mainColor10;
  }

  input:read-only {
    color: $mainColor5;
  }

  /* active state */
  input:focus {
    background: $inputFieldBGFocus;

    &:read-only {
      border-bottom: 1px dashed $mainColor10;
    }
  }

  input:disabled {
    & ~ .reqMark {
      opacity: 0;
    }
  }

  input.hasValue, input.opened {
    border-bottom: 1px solid transparent;

    & ~ label {
      font-size: $inputLabelFontSize;
      color: $mainColor0;
      top: 100%;
      transform: translate(0, 0);
    }

    & ~ .bar {
      transform: scaleY(1.0);
    }

    & ~ .reqMark {
      opacity: 0;
    }

    & ~ .calendarWrapper {
      bottom: -($inputLabelHeight);
    }
  }

  input.opened {
    border-bottom: 1px solid $mainColor10;
  }

  .reqMark {
    pointer-events: none;
    position: absolute;
    z-index: 5;

    &.line {
      height: 0;
      transition: 0.3s ease width;
      transform-origin: 0 0;
    }
  }

  &.required {
    .reqMark {
      &.line {
        left: 0;
        bottom: -1px;
        width: 30px;
        max-width: 30%;
        height: 2px;
        background: linear-gradient(to right, $mainColor1 0%, $mainColor1 30%, rgba($mainColor1, 0) 100%);
      }
    }
  }

  &.modified {
    &:after {
      position: absolute;
      z-index: 1;
      content: '';
      right: 1px;
      top: 1px;
      width: 0;
      height: 0;
      border-right: 3px solid $mainColor1;
      border-top: 3px solid $mainColor1;
      border-bottom: 3px solid transparent;
      border-left: 3px solid transparent;
    }
  }
}

.waveDelimiter {
  background: url('../img/waveGreen.svg') repeat-x left center;
  height: 10px;
  align-self: stretch;
}


.topBarContent {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  padding: 10px;
  min-width: 0;
  img {
    width: 24px;
    margin: 0 10px;
  }
  @include sm {
    img {
      width: 36px;
      margin: 0 20px;
    }
  }
  .topBarTitle {
    flex: 1;
    font-weight: $semiNormalFont;
    font-size: 1.8rem;
    padding: 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    display: inline-block;
    @include sm {
      font-size: 2.8rem;
    }
  }
}


.toast-container {
  min-width: 200px;
  @include sm {
    min-width: auto;
  }

  .toast {
    max-width: 80vw;
    @include sm {
      max-width: 30vw;
    }
  }
}

//noinspection CssInvalidPropertyValue
.overlay {
  width: 100vw;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

