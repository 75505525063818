@import 'colors.scss';
@import 'functions.scss';
@import 'variables.scss';

$dialogBackground: $mainColor22;
$dialogPadding: 1rem;
$dialogRadius: 6px;
$dialogTitlePadding: 1.8rem;

.nsm-dialog {

  &.w640 {
    max-width: 640px;
  }

  &.w800 {
    max-width: 800px;
  }

  &.w1000 {
    max-width: 1000px;
  }

  &.form {
    background: $mainColor12;

    .dialogTitle {
      background: $mainColor7;
    }
  }

}

.nsm-content {
  background: $dialogBackground !important;
  border-radius: 0 !important;
  @include sm {
    max-width: calc(100% - #{2*$dialogPadding});
    max-height: calc(100% - #{2*$dialogPadding}); //IE11
    border-radius: $dialogRadius !important;
  }

  .dialogMessage {
    padding: 20px;
    margin-bottom: 8px;
  }

  .dialogTitle {
    padding: 10px;
    margin: (-$dialogPadding) (-$dialogPadding) 20px (-$dialogPadding);
    border-radius: $dialogRadius $dialogRadius 0 0;
    font-family: $portal-font-family-narrow;
    font-size: 2rem;
    background: $mainColor10;
    position: relative;
    @include sm {
      padding: $dialogTitlePadding;
    }
  }

  .dialogSubTitle {
    padding: 10px 0;
    font-size: 1.3rem;
  }

  .dialogButton {
    margin: 2px 0 2px 8px;
    line-height: 1;
    font-size: 1.2rem;
    padding: 0.8rem 1.5rem;

    border-radius: 3px;
    border: 0;
    float: right;
    font-family: inherit;
    text-transform: uppercase;
    letter-spacing: .1em;

    text-rendering: optimizeLegibility;
    appearance: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    &:disabled {
      cursor: default;
    }

    @include sm {
      font-size: 1.4rem;
      padding: 1rem 3rem;
    }

    &.dialogButtonPrimary {
      background: $mainColor1;
      color: $mainColor0;
      font-weight: $boldFont;

      &:hover {
        background: $mainColor4;
      }
    }

    &.dialogButtonSecondary {
      background: #e0e0e0;

      &:hover {
        background: $mainColor9;
      }
    }


    &:focus:before {
      border-radius: 6px;
      border-color: $mainColor6;
    }
  }

  &.withContent {
    .dialogMessage {
      padding: 10px;
    }
  }

  .dialogButtons {
    button {
      margin-top: 10px;
    }
    /* clear fix */
    @include clearFix();

    @include sm {
      button {
        margin-top: $dialogTitlePadding;
      }
    }
  }

}


.dialogContent {
  flex: 1;
  overflow-y: auto;
}

@keyframes slideFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
};


@mixin bottomDialog() {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: none !important;
  align-items: flex-end;
  overflow: hidden;
  .nsm-content {
    transform: translateY(100%);
    animation: slideFromBottom 0.5s $defaultAnimationEasing forwards;
    margin: auto 0 0 0;
    min-height: 0;
    width: 100%;
    max-width: none !important;
    padding: 0;
    border-radius: 0 !important;
    background: none !important;
    box-shadow: none;
    pointer-events: none;
  }

  .nsm-body {
    display: flex;
    flex-direction: column;
    min-height: 0;
  }

  .dialogButtons {
    button {
      margin: 10px 10px 10px 5px;
    }
  }

}
