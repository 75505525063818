@use "sass:math";

// @source - [@Stephn-R](https://github.com/sass/sass/issues/1395#issuecomment-57483844)
// @description converts 1 or more characters into a unicode 
// @markup {scss}
// unicode("e655"); // "\e655"
@function unicode($str){
  @return unquote("\"")+unquote(str-insert($str, "\\", 1))+unquote("\"")
}

@function decToHex($dec) {
  $hex: "0123456789ABCDEF";
  $first: math.div($dec - $dec % 16, 16)+1;
  $second: ($dec % 16)+1;
  @return str-slice($hex, $first, $first) + str-slice($hex, $second, $second)
}

@mixin clearFix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

@mixin maxWidthPage($width: null) {
  margin: 0 auto;
  @if ($width) {
    max-width: $width;
  } @else {
    max-width: 800px;
  }
}

@mixin apply-to-IE() {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
  @media screen and (min-width:0\0) {
    @content;
  }
}

@mixin svgIconSize($width: null, $height: null) {
  ::ng-deep {
    svg {
      width: $width;
      @if ($height) {
        height: $height;
      } @else {
        height: $width;
      }
    }
  }

}

@mixin textButtonWithIcon($btnIconSize: null, $iconMargin: null, $iconLeftPos: null) {
  margin: 0;
  display: block;
  background-color: transparent;
  text-transform: uppercase;
  text-align: left;
  padding: 12px 20px 12px ($btnIconSize + $iconMargin);
  position: relative;
  &:before {
    left: $iconLeftPos;
    top: 50%;
    position: absolute;
    content: '';
    width: $btnIconSize;
    height: $btnIconSize;
    transform: translate(0, -50%);
  }

}

